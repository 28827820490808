#Aboutpage{
width: 100%;  
}


#Aboutpage .text-content-section{
width: 100%;
height: 400px;
background-color: black;    
}

#Aboutpage .image-content-section{
width: 100%;
height: 400px; 
}

#Aboutpage img{
width: 100%;    
}

#Aboutpage .mission-photo{
max-width: 80%;
}

.mb-4{
font-size: 19px;
text-align: justify;
word-spacing: 0px; 
 
}