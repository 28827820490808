




.project-photo img{
max-width: 100%;    
}

.project-grid-container{
border-bottom: 2px solid gainsboro;    
}