


#project-table th{
background-color: #091E3E;
padding: 10px;  
color: #fff;  
font-size: 28px;
text-transform: capitalize;
}
//

#project-table td{
background-color:#06A3DA; 
padding: 10px;  
color: #fff;  
text-align: start;
}

#Servicepage p{
text-align: justify;
font-size: 18px;    
}